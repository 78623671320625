<template>
  <PageListLayout
    icon="mdi-account"
    title="Tài khoản"
    subTitle="Thông tin tài khoản"
    noFilter
  >
    <div class="d-flex align-center justify-center">
      <div class="d-flex" style="width: 800px">
        <div>
          <v-avatar
            class="pointer"
            v-on="on"
            color="var(--primary-color)"
            size="150"
            @click="uploadAvatar"
          >
            <img
              v-if="USER && USER.url_image"
              :src="'https://apinhansu.mauxanhcuocsong.vn/' + USER.url_image"
              alt=""
            />
            <span class="white--text headline" v-else-if="USER && USER.name">{{
              USER.name.charAt(0).toUpperCase()
            }}</span>
   
          </v-avatar>
          <div class="mt-4 text-center">
            <v-btn
              class="text-capitalize"
              rounded
              color="primary"
              dark
              small
              @click="changePass()"
            >
              <v-icon left small> mdi-key </v-icon>
              Đổi mật khẩu
            </v-btn>
          </div>
        </div>
        <div class="ml-5 d-flex flex-column justify-space-evenly">
          <div class="wrap_info">
            <v-form ref="form">
              <v-expansion-panels accordion v-model="panel">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col cols="4">
                        <strong>Họ tên:</strong>
                      </v-col>
                      <v-col cols="8">
                        {{ USER.name }}
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <v-row>
                        <v-col cols="4"></v-col>
                        <v-col cols="8" class="pl-1">
                          <div>
                            <v-text-field
                              v-model="formChange.name"
                              placeholder="Tên"
                              outlined
                              dense
                              hide-details="auto"
                              :rules="nameRule"
                            ></v-text-field>
                          </div>
                          <v-btn
                            class="btn_custom"
                            depressed
                            color="var(--primary-color)"
                            @click="saveInfo"
                          >
                            Lưu
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col cols="4">
                        <strong>Email:</strong>
                      </v-col>
                      <v-col cols="8">
                        {{ USER.email }}
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <v-row>
                        <v-col cols="4"></v-col>
                        <v-col cols="8" class="pl-1">
                          <div>
                            <v-text-field
                              v-model="formChange.email"
                              placeholder="Email"
                              :rules="emailRules"
                              outlined
                              dense
                              hide-details="auto"
                            ></v-text-field>
                          </div>
                          <v-btn
                            class="btn_custom"
                            depressed
                            color="var(--primary-color)"
                            @click="saveInfo"
                          >
                            Lưu
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col cols="4">
                        <strong>Tên đăng nhập:</strong>
                      </v-col>
                      <v-col cols="8">
                        {{ USER.user_name }}
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <v-row>
                        <v-col cols="4"></v-col>
                        <v-col cols="8" class="pl-1">
                          <div>
                            <v-text-field
                              v-model="formChange.user_name"
                              placeholder="Tên đăng nhập"
                              outlined
                              dense
                              hide-details="auto"
                              :rules="user_nameRule"
                            ></v-text-field>
                          </div>
                          <v-btn
                            class="btn_custom"
                            depressed
                            color="var(--primary-color)"
                            @click="saveInfo"
                          >
                            Lưu
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel disabled>
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col cols="4">
                        <strong>Quyền hệ thống:</strong>
                      </v-col>
                      <v-col cols="8">
                        {{
                          USER && USER.role
                            ? `${USER.role.name}(${USER.role.description})`
                            : ""
                        }}
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content> abc </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel disabled>
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col cols="4">
                        <strong>Ngày tham gia:</strong>
                      </v-col>
                      <v-col cols="8">
                        {{ formatDate(USER.created_at) }}
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content> abc </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="showFormChangePass" persistent width="500">
      <v-card>
        <v-card-title class="headline">THAY ĐỔI MẬT KHẨU</v-card-title>
        <br />
        <v-card-text>
          <v-form ref="form">
            <div class="label-form">Mật khẩu hiện tại</div>
            <v-text-field
              type="password"
              v-model="changePassWord.currentPass"
              :rules="rulePass.currentPass"
              placeholder="Nhập mật khẩu hiện tại"
              outlined
              dense
              prepend-inner-icon="mdi-account-key"
            ></v-text-field>
            <div class="label-form">Mật khẩu mới</div>
            <v-text-field
              type="password"
              v-model="changePassWord.newPassWord"
              :rules="rulePass.newPassWord"
              placeholder="Nhập mật khẩu mới"
              outlined
              dense
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
            <div class="label-form">Xác nhận mật khẩu mới</div>
            <v-text-field
              type="password"
              v-model="changePassWord.reNewPassWord"
              :rules="[reNewPassWord]"
              placeholder="Nhập lại mật khẩu mới"
              outlined
              dense
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showFormChangePass = false" class="mr-4"
            >Đóng</v-btn
          >
          <v-btn :loading="btnLoading" color="primary" @click="updatePass">
            <v-icon left>mdi-pencil</v-icon>
            Cập nhật
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </PageListLayout>
</template>
<script>
import Inbox from "./dashboard/inbox";
import { changePassword,updateUserChange } from "@/api/user";
import avatarNone from "../../../../docs/img/avatar_none.png";
import masterialPic from "../../../../docs/img/masterial.png";
import { uploadAvatarProfile } from "@/api/user";

export default {
  // components: {Inbox},
  data: () => ({
    avatarNone,
    masterialPic,
    tab: null,
    imageEndpoint: process.env.VUE_APP_BASE,
    showFormChangePass: false,
    changePassWord: {
      currentPass: null,
      newPassWord: null,
      reNewPassWord: null,
    },
    btnLoading: false,
    rulePass: {
      currentPass: [(v) => !!v || "Hãy nhập mật khẩu hiện tại"],
      newPassWord: [(v) => !!v || "Hãy nhập mật khẩu mới"],
    },
    formChange: {},
    panel: null,

    //rule empty
    nameRule: [(v) => !!v || "Tên không thể bỏ trống"],
    user_nameRule: [(v) => !!v || "Username không thể bỏ trống"],
    emailRules: [
      (v) => !!v || "E-Mail không thể bỏ trống",
      (v) => /.+@.+\..+/.test(v) || "E-mail không hợp lệ",
    ],
  }),
  computed: {
    USER() {
      return this.$store.state.User.me;
    },
    reNewPassWord() {
      if (!this.changePassWord.reNewPassWord)
        return () => "Hãy nhập lại mật khẩu mới";
      return () =>
        this.changePassWord.newPassWord === this.changePassWord.reNewPassWord ||
        "Mật khẩu không trùng khớp";
    },
  },
  watch: {
    panel(val) {
      this.formChange = {
        name: this.USER.name,
        email: this.USER.email,
        user_name: this.USER.user_name,
      };
    },
  },
  methods: {
    formatDate(e) {
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return ngay + "/" + thang + "/" + date.getFullYear();
      } catch (error) {
        return "";
      }
    },
    uploadAvatar() {
      this.$refs["upload-image"].click();
    },
    async handleUpload(e) {
      let files = e.target.files;
      let data = new FormData();
      data.append("file", files[0]);

      var filePath = files[0].name.split(".").pop(); //lấy định dạng file
      var dinhDangChoPhep = ["jpg", "jpeg", "png"]; //các tập tin cho phép
      const isLt2M = files[0].size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.form.fileList.pop();
        this.$toast.warning("Kích thước file ảnh tối đa 20Mb!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return false;
      }
      if (!dinhDangChoPhep.find((el) => el == filePath.toLowerCase())) {
        this.loadingUpload = false;
        this.listLoading = false;
        this.iconUpload = "el-icon-bottom";
        this.$toast.warning("Tập tin không hợp lệ!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return;
      }
      try {
        await uploadAvatarProfile(data);
        this.$store.dispatch("User/getMe");
      } catch (error) {
        console.log(error);
      }
      this.$refs["upload-image"].value = null;
    },
    changePass() {
      this.showFormChangePass = true;
      this.changePassWord = {
        currentPass: null,
        newPassWord: null,
        reNewPassWord: null,
      };
    },
    async updatePass() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        try {
          this.btnLoading = true;
          await changePassword(this.changePassWord);
          this.showFormChangePass = false;
          this.btnLoading = false;
          this.changePassWord = {
            currentPass: null,
            newPassWord: null,
            reNewPassWord: null,
          };
          this.$toast.info("Đổi mật khẩu thành công!", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
          window.location.reload();
        } catch (error) {
          this.btnLoading = false;
        }
      }
    },
   async  saveInfo() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.formChange.id=this.$store.state.User.me.id;
        try{
           const {data} = await updateUserChange(this.formChange);//dashboard

           this.$toast.success("Cập nhật tài khoản thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
          this.$store.state.User.me=data
          // this.$router.push({ path: '/' })

        }catch(e){
          console.log(e);
        }
      }
    },
  },
  created() {},
};
</script>
<style scoped>
.wrap_info {
  border-radius: 8px;
  border: 1px solid #dadce0;
  box-sizing: border-box;
}
.style_field {
  display: inline-block;
  width: 120px;
}
.justify-space-evenly {
  justify-content: space-evenly;
}
.btn_custom {
  margin-top: 12px;
  color: white;
  height: 28px !important;
  min-width: 50px !important;
  font-size: 12px;
}
</style>